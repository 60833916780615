<template>
  <v-app>
    <div>
      <slot />
    </div>
  </v-app>
</template>

<script >
export default {
  name: "AppLayoutDefault",
};
</script>
